import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import useRedirectIfAuthenticated from "../hooks/useRedirectIfAuthenticated.js";
import { login } from "../utils/api.ts";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useRedirectIfAuthenticated("/профиль/");

  const handleLogin = async (values) => {
    setLoading(true);
    try {
      const { data } = await login(values);
      localStorage.setItem("token", data.token);
      message.success("Успешный вход");
      navigate("/профиль/");
    } catch (error) {
      console.error("Login error", error);
      message.error(
        "Войти не удалось. Попробуйте снова."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <h1 className="title mb-2">Вход в кабинет</h1>
      <Form
        name="login_form"
        onFinish={handleLogin}
        layout="vertical"
        style={{
          maxWidth: 400,
          margin: "0 auto",
          padding: "20px",
          border: "1px solid #d9d9d9",
          borderRadius: "8px",
        }}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Пожалуйста, введите ваш email!" },
            { type: "email", message: "Пожалуйста, введите корректный email!" },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[{ required: true, message: "Пожалуйста, введите пароль!" }]}
        >
          <Input.Password placeholder="Пароль" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Логин
          </Button>
        </Form.Item>
        <span className="link" onClick={() => navigate("/регистрация/")}>Регистрация</span>
      </Form>
    </>
  );
};

export default LoginForm;
