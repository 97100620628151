import React from "react";
import { SeoArticleProps } from "../types";

const SeoArticle: React.FC<SeoArticleProps> = ({
  location = "в вашем городе",
  serviceType = "на дом",
  priceFrom = 5000,
}) => {
  return (
    <section className="block">
      <h2 className="subtitle">
        Заказать Деда Мороза {location} {serviceType}
      </h2>
      <article>
        <p className="article_text">
          Порадуйте себя и своих близких настоящим зимним волшебством — закажите
          визит Деда Мороза и Снегурочки {location}. Наши профессиональные
          артисты приедут {serviceType}, чтобы создать незабываемую новогоднюю
          атмосферу. Дети будут в восторге от встречи с любимыми персонажами, а
          взрослые смогут окунуться в праздничное настроение и забыть о
          повседневных заботах.
        </p>
        <p className="article_text">
          Дед Мороз и Снегурочка — это символы Нового года, а визит в канун
          праздника станет особенным событием. Каждый наш актер — опытный
          профессионал, который умеет развеселить и увлечь даже самых
          застенчивых детей. Закажите визит {serviceType} для себя или как
          сюрприз для ваших близких {location}!
        </p>
        <p className="article_text">
          Праздник совсем близко, не упустите шанс подарить себе и своей семье
          настоящую сказку. Вы можете оформить заказ прямо на нашем сайте и
          выбрать время, удобное именно вам. Дед Мороз и Снегурочка готовы
          отправиться в путь, чтобы сделать ваш Новый год незабываемым. Не
          ждите, ведь волшебство начинается именно сейчас!
        </p>
        <h3 className="subtitle">
          Сколько стоит вызов Деда Мороза {location} {serviceType}?
        </h3>
        <p className="article_text">
          Стоимость визита Деда Мороза {location} {serviceType} начинается от{" "}
          {priceFrom} рублей. Цена зависит от продолжительности программы,
          выбранных дополнительных услуг и даты праздника. Мы предлагаем разные
          пакеты услуг, которые можно подобрать в зависимости от ваших
          предпочтений и бюджета. Обращайтесь к нам заранее, чтобы выбрать
          удобное время и гарантировать, что Дед Мороз приедет именно к вам!
        </p>
      </article>
    </section>
  );
};

export default SeoArticle;
