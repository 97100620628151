import React from 'react';


const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer__logo">
        <img src="/logo.png" alt="Logo" className="footer__logo" />
      </div>
      <div className="footer__info">
        <p>© 2024 ООО "ЦК". Все права защищены.</p>
      </div>
    </footer>
  );
};

export default Footer;
