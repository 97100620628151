import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


const Loading = () => {
  return (
    <div className="spinner"><Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} /></div>
  )
}

export default Loading