// hooks/useRedirectIfAuthenticated.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateToken } from '../utils/api.ts';

const useRedirectIfAuthenticated = (redirectPath) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await validateToken();
        navigate(redirectPath);
      } catch (error) {
        localStorage.removeItem('token');
      }
    };

    checkAuth();
  }, [navigate, redirectPath]);
};

export default useRedirectIfAuthenticated;
