import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterState } from '../../types';


const initialState: FilterState = {
  city: null,
  serviceType: null,
};

const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setCity(state, action: PayloadAction<string>) {
      state.city = action.payload;
    },
    setServiceType(state, action: PayloadAction<string>) {
      state.serviceType = action.payload;
    },
  },
});

export const { setCity, setServiceType } = filterSlice.actions;
export default filterSlice.reducer;
