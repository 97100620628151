import React, { useEffect } from 'react';

const MAX_SNOWFLAKES = 50;

const Snowflakes: React.FC = () => {
  const snowflakes: HTMLDivElement[] = [];

  const createSnowflake = () => {
    const snowflake = document.createElement("div");
    snowflake.classList.add("snowflake");
    snowflake.innerHTML = "&#10052;";

    // Случайные размеры и позиции снежинок
    snowflake.style.left = `${Math.random() * 100}vw`;
    snowflake.style.fontSize = `${Math.random() * 10 + 10}px`;
    snowflake.style.animationDuration = `${Math.random() * 100 + 2}s`;

    document.body.appendChild(snowflake);
    snowflakes.push(snowflake);

    // Удаляем снежинку после анимации
    snowflake.addEventListener("animationend", () => {
      snowflake.remove();
      // Удаляем элемент из массива после его исчезновения
      const index = snowflakes.indexOf(snowflake);
      if (index > -1) {
        snowflakes.splice(index, 1);
      }
    });

    // Проверка количества снежинок и удаление старых
    if (snowflakes.length > MAX_SNOWFLAKES) {
      const oldSnowflake = snowflakes.shift();
      oldSnowflake?.remove();
    }
  };

  useEffect(() => {
    const interval = setInterval(createSnowflake, 100);
    return () => clearInterval(interval);
  }, []);

  return null;
};

export default Snowflakes;
