import React from "react";
import { Collapse } from "antd";

const faqData = [
    {
        key: "1",
        label: "Кто такой Дед Мороз?",
        children:
            "Дед Мороз — это мифический персонаж, который приносит подарки детям на Новый год.",
    },
    {
        key: "2",
        label: "Как заказать Деда Мороза?",
        children:
            "Вы можете заказать Деда Мороза через наш сайт, выбрав подходящие дату и время, после чего мы свяжем вас с исполнителем.",
    },
    {
        key: "3",
        label: "Какие подарки могут быть у Деда Мороза?",
        children:
            "Деды Морозы, с которыми мы работаем, предлагают разнообразные подарки, которые вы можете обсудить с ними напрямую.",
    },
    {
        key: "4",
        label: "Какова стоимость услуг?",
        children:
            "Стоимость услуг варьируется в зависимости от исполнителя и выбранного вами сценария. Мы рекомендуем обсудить детали с Дедом Морозом.",
    },
    {
        key: "5",
        label: "Как выбрать подходящего Деда Мороза?",
        children:
            "На нашем сайте вы можете просмотреть профили различных Дедов Морозов, включая их рейтинги и отзывы от других клиентов.",
    },
    {
        key: "6",
        label: "Есть ли у вас Деды Морозы в моем городе?",
        children:
            "Да, у нас есть исполнители в разных городах. Вы можете выбрать свой город при поиске.",
    },
    {
        key: "7",
        label: "Как проходит встреча с Дедом Морозом?",
        children:
            "Каждый Дед Мороз имеет свой сценарий встречи, который вы можете обсудить напрямую с ним после заказа.",
    },
    {
        key: "8",
        label: "Можно ли заказать Деда Мороза для взрослых?",
        children:
            "Да, через наш сервис можно заказать Деда Мороза для различных мероприятий, включая вечеринки и корпоративы.",
    },
    {
        key: "9",
        label: "Как мне отменить заказ?",
        children:
            "Если вам нужно отменить заказ, пожалуйста, свяжитесь с исполнителем, с которым вы договорились, через нашу платформу.",
    },
    {
        key: "10",
        label: "Что делать, если Дед Мороз опоздал?",
        children:
            "В случае опоздания Деда Мороза, пожалуйста, свяжитесь с ним напрямую через нашу платформу для уточнения ситуации.",
    },
    {
        key: "11",
        label: "Можно ли попросить особые пожелания для Деда Мороза?",
        children:
            "Да, вы можете указать свои пожелания при заказе, и мы передадим их исполнителю.",
    },
    {
        key: "12",
        label: "Как я могу оплатить услуги?",
        children:
            "Оплата услуг производится непосредственно с Дедом Морозом. Обсудите с ним способы оплаты, которые ему удобны.",
    },
];

const FAQ: React.FC = () => {
    return (
        <div className="block" >
            <h2 className='subtitle'>Часто задаваемые вопросы</h2>
            <Collapse accordion items={faqData}  className="text"/>
        </div>
    );
};

export default FAQ;
