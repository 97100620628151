import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateToken } from '../utils/api.ts';

const useAuthRedirect = (redirectPath) => {
  const navigate = useNavigate();
  const [isAuthenticatedChecked, setIsAuthenticatedChecked] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await validateToken();
        setIsAuthenticatedChecked(true);
      } catch (error) {
        localStorage.removeItem('token');
        navigate(redirectPath);
      }
    };

    checkAuth();
  }, [navigate, redirectPath]);

  return isAuthenticatedChecked;
};

export default useAuthRedirect;
