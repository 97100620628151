import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchCities } from '../../utils/api.ts';
import { CityState, CitiesResponse } from '../../types';

const initialState: CityState = {
  cities: [],
  serviceTypes: [],
  loading: false,
  error: null,
};

export const fetchCitiesSlice = createAsyncThunk('cities/fetchCitiesSlice', async () => {
  const response = await fetchCities();
  return response;
});

const citySlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCitiesSlice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCitiesSlice.fulfilled, (state, action: PayloadAction<CitiesResponse>) => {
        state.loading = false;
        state.cities = action.payload.cities;
        state.serviceTypes = action.payload.serviceTypes;
      })
      .addCase(fetchCitiesSlice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to load cities';
      });
  },
});

export default citySlice.reducer;
