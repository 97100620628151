import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Result
      status="404"
      title="404"
      subTitle="К сожалению, страница, которую вы посетили, не существует."
      extra={
        <Button type="primary" onClick={handleGoBack}>
          Вернуться назад
        </Button>
      }
    />
  );
};

export default NotFound;
