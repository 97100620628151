// src/components/Filter.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCity, setServiceType } from "../store/slices/filterSlice.ts";
import { RootState } from "../store/store.ts";
import { Radio, Space } from "antd";
import Loading from "./Loading.tsx";

const Filter: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const city = useSelector((state: RootState) => state.filters.city);
  const cities = useSelector((state: RootState) => state.cities.cities);
  const loading = useSelector((state: RootState) => state.cities.loading);

  const serviceTypes = useSelector(
    (state: RootState) => state.cities.serviceTypes
  );
  const serviceType = useSelector(
    (state: RootState) => state.filters.serviceType
  );

  const handleCityChange = (city: string) => {
    dispatch(setCity(city));
    const url = `/листинг/${city}${serviceType ? `/${serviceType}` : ""}`;
    navigate(url);
  };

  const handleServiceTypeChange = (type: string) => {
    dispatch(setServiceType(type));
    const url = `/листинг/${city ? `${city}/` : ""}${type}`;
    navigate(url);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <h3 className="h3">Выберите город</h3>
      <div className="filter_block">
        <Radio.Group
          onChange={(e) => handleCityChange(e.target.value)}
          value={city}
        >
          <Space direction="vertical">
            {cities.map((cityOption) => (
              <Radio
                key={cityOption.id}
                value={cityOption.name}
                className="text"
              >
                {cityOption.name}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>

      <h3 className="h3">Тип услуги</h3>
      <div className="filter_block">
        <Radio.Group
          onChange={(e) => handleServiceTypeChange(e.target.value)}
          value={serviceType}
        >
          <Space direction="vertical">
            {serviceTypes.map((type) => (
              <Radio key={type} value={type} className="text">
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
    </div>
  );
};

export default Filter;
